export default {
  async selectArticulo (Vue, search=null) {
    const tables = Vue.$offline.db.tables
    let where = []
    let whereSearch = []
    if (search) {
      whereSearch = Vue.$offline.articulo.searchFilter(search)
    }
    where.push(
      Vue.$offline.db.op.and(
        tables.articulo.estado.gt(0),
        ...whereSearch,
      )
    )
    return (await Vue.$offline.db
      .select()
      .from(tables.articulo)
      .innerJoin(
        tables.subfamilia,
        tables.articulo.idsubfamilia.eq(tables.subfamilia.idsubfamilia)
      )
      .innerJoin(
        tables.familia,
        tables.subfamilia.idfamilia.eq(tables.familia.idfamilia)
      )
      .where(...where)
      .exec())
  },
}
