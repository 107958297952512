<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    >
      <v-btn
        slot="right"
        icon
        @click.stop="clickSubmit($refs.form)"
      >
        <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
      </v-btn>
    </b10-toolbar>
    <b10-page-content
      :padding="3"
    >
      <ValidationObserver
        ref="form"
      >
        <material-sistema-add-form
          :idsubsis="routeParams.idsubsis"
          :idsistema="routeParams.idsistema"
          @change-values="changeFormData"
        />
      </ValidationObserver>
    </b10-page-content>
  </b10-base>
</template>

<script>
import { formPageMixin } from '@/mixins/formPageMixin'
import MaterialSistemaAddForm from './components/MaterialSistemaAddForm'
import Data from './MaterialSistemaAddData'
import { encodeExtraQuery } from '@/utils/router'

export default {
  components: {
    MaterialSistemaAddForm
  },
  mixins: [formPageMixin],
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.title = 'Añadir material de sistema'
    },
    changeFormData (formData) {
      this.formData = formData
    },
    async submitForm () {
      let idsMateriales = []
      idsMateriales = await Data.addMaterialSistemaNuevo(
        this,
        this.routeParams.idsistema,
        this.formData.articulo.articulo.idarticulo,
        this.formData.unidades,
        this.formData
      )
      await this.$dirty.modified(this.$dirty.ENTITIES.local.subsis, this.formData.idsubsis)
      this.$appRouter.replace({
        name: 'offline__material-sistema-edit',
        params: {
          idmaterial_sistema: idsMateriales[0],
        },
        query: {
          extra: encodeExtraQuery({ ids: idsMateriales }),
        },
      })
    },
  },
}
</script>
