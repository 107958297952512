import { v4 as uuidv4 } from 'uuid'

export default {
  async addMaterialSistemaNuevo (Vue, idsistema, idarticulo, unidades, formData) {
    let idsMateriales = []
    let idsubsis
    // no existe el subsistema
    if (formData.idsubsis_idtsubsis.tipo === 'tsubsis') {
      idsubsis = uuidv4()
      await Vue.$offline.subsis.insertSync({
        idsubsis,
        idsistema: idsistema,
        idtsubsis: formData.idsubsis_idtsubsis.idtsubsis,
        descripcion: formData.subsis_descripcion,
        codigo: formData.subsis_codigo,
        estado: 1,
      })
    } else {
      idsubsis = formData.idsubsis_idtsubsis.id
    }
    if (formData.desglosar) {
      let count = 0
      while (count < formData.unidades) {
        idsMateriales.push(
          await Vue.$offline.materialSistema.insertarMaterialSistema(
            idarticulo=idarticulo,
            unidades=1,
            idsubsis=idsubsis
          )
        )
        count++
      }
    } else {
      idsMateriales.push(
        await Vue.$offline.materialSistema.insertarMaterialSistema(
          idarticulo=idarticulo,
          unidades=unidades,
          idsubsis=idsubsis
        )
      )
    }
    return idsMateriales
  }
}
