<template>
  <b10-base>
    <ValidationProvider
      v-slot="{ errors }"
      name="articulo"
      :rules="formRules.articulo"
    >
      <b10-autocomplete
        v-model="form.articulo"
        :items="articulos"
        item-text="articulo.descripcion"
        label="Artículo"
        clearable
        :error-messages="errors"
        :search-input.sync="articuloSearch"
        return-object
        no-filter
        @change="articuloSelected"
      >
        <template #item="slotProps">
          <v-list-item-content>
            <v-list-item-title>{{ slotProps.item.articulo.descripcion }}</v-list-item-title>
            <v-list-item-subtitle>{{ slotProps.item.articulo.codigo }}</v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </b10-autocomplete>
    </ValidationProvider>
    <b10-autocomplete-alert
      v-if="form.articulo"
    >
      {{ form.articulo.articulo.descripcion }}<br>{{ form.articulo.articulo.codigo }}
    </b10-autocomplete-alert>
    <v-alert
      v-if="form.idsubsis_idtsubsis && form.idsubsis_idtsubsis.tipo === 'tsubsis'"
      color="warning"
      dark
      small
    >
      Se creará un nuevo subsistema
    </v-alert>
    <b10-autocomplete
      v-model="form.idsubsis_idtsubsis"
      :disabled="!!idsubsis"
      :items="subsisTsubsis"
      :rules="formRules.idsubsis_idtsubsis"
      item-value="value"
      item-text="descripcion"
      label="Subsistema"
      clearable
      @change="subsisTsubsisSelected"
    >
      <template
        #item="{ item, attrs, on }"
      >
        <v-list-item
          v-bind="attrs"
          :disabled="item.value.tipo === 'divider'"
          v-on="on"
        >
          <v-list-item-content
            v-if="item.value.tipo === 'divider'"
            :class="item.value.color"
            class="white--text pa-1 rounded"
          >
            {{ item.descripcion }}
          </v-list-item-content>
          <v-list-item-content
            v-else
          >
            {{ item.descripcion }}
          </v-list-item-content>
        </v-list-item>
      </template>
    </b10-autocomplete>

    <v-text-field
      v-model.number="form.unidades"
      class="number-input"
      label="Unidades"
      type="Number"
    />
    <v-checkbox
      v-if="!!form.articulo && form.unidades > 1"
      v-model="form.desglosar"
      label="Desglosar unidades"
    />
  </b10-base>
</template>

<script>
import _ from '@/utils/lodash'
import formMixin from '@/mixins/formMixin'
import Data from './MaterialSistemaAddFormData'

export default {
  mixins: [formMixin],
  props: {
    idsubsis: {
      type: [Number, String],
      default: null,
    },
    idsistema: {
      type: [Number, String],
      default: null,
    }
  },
  data () {
    return {
      form: {
        idsubsis_idtsubsis: null,
        subsis_codigo: null,
        subsis_descripcion: null,
        articulo: null,
        unidades: 1,
        desglosar: true,
      },
      formRules: {
        articulo: { required: true },
        unidades: { required: true },
        idsubsis_idtsubsis: [
          v => !!v || 'Campo requerido',
        ],
        subsis_codigo: [
          v => !!v || 'Campo requerido',
          v => {
            return !this.inCodigosExistentes.includes(v) || 'Código de subsistema duplicado'
          }
        ],
        subsis_descripcion: [
          v => !!v || 'Campo requerido',
        ],
      },
      articulos: [],
      articuloSearch: '',
      subsisTsubsis: [],
    }
  },
  computed: {
  },
  watch: {
    articuloSearch (value) {
      // cancel pending call
      clearTimeout(this._timerId)
      // Esperar 500ms antes de buscar
      this._timerId = setTimeout(() => {
        value && value.length >= 3 && value !== this.form.articulo && this.articuloDoSearch(value)
      }, 500)

    },
  },
  async created () {
    this.subsisTsubsis = await this.$offline.subsis.selectSubsisTsubsis(this.idsistema)
    if (this.idsubsis){
      this.$set(this.form, 'idsubsis_idtsubsis', this.findSubsisIdsubsis(this.idsubsis))
    }
    await this.loadForm()
  },
  methods: {
    async articuloDoSearch (value) {
      this.articulos = await Data.selectArticulo(this, value)
    },
    async articuloSelected (value) {
      if (this.idsubsis === null){
        if (value) {
          let idsubsis = await this.$offline.subsis.selectSubsisPorDefecto(this.subsisTsubsis, value.articulo.idarticulo)
          // forzar evento
          this.$set(this.form, 'idsubsis_idtsubsis', idsubsis)
          this.subsisTsubsisSelected()
        } else {
          this.$set(this.form, 'idsubsis_idtsubsis', null)
          this.subsisTsubsisSelected()
        }
      }
    },
    subsisTsubsisSelected () {
      if (
        this.form.idsubsis_idtsubsis &&
        this.form.idsubsis_idtsubsis.codigoPorDefecto &&
        this.form.idsubsis_idtsubsis.descripcionPorDefecto
      ) {
        this.$set(this.form, 'subsis_codigo', this.form.idsubsis_idtsubsis.codigoPorDefecto)
        this.$set(this.form, 'subsis_descripcion', this.form.idsubsis_idtsubsis.descripcionPorDefecto)
      } else {
        this.$set(this.form, 'subsis_codigo', null)
        this.$set(this.form, 'subsis_descripcion', null)
      }
    },
    findSubsisIdsubsis (idsubsis) {
      const indexSubsis = _.findIndex(
        this.subsisTsubsis, {
          value: {
            // idsubsis string -> se puede insertar offline
            id: idsubsis.toString(),
          }
        }
      )
      if (indexSubsis > -1) {
        return this.subsisTsubsis[indexSubsis].value
      }
    },
  },
}
</script>

<style scoped>
.number-input >>> input {
  text-align: right;
}
</style>
